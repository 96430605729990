import React from "react";
import "./ServicesBanner.css";

const ServicesBanner = () => {
  return (
    <div className="services-banner">
      <h2>Services</h2>
    </div>
  );
};

export default ServicesBanner;
