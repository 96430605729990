import React from "react";
import pic6 from "./pic6.jpg";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <img src={pic6} />
    </div>
  );
}

export default Banner;
